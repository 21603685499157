$default-color: "#1c9ad7";
$pro-tag: "#009dd9";
$link-pressed: "#0078b2";
$link-visited: "#85a7b8";
$demo-button-hover: "#5bc1f2";
$demo-button-hover-dark: "#64c8ee";
$svg-base: "#009dd9";
#image-modal {
  background: #00000057;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999;
  transition: all 300ms ease-in-out;
  visibility: visible;
  opacity: 1;
  &[aria-hidden="true"] {
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    z-index: -10000;
  }

  img {
    display: block;
    max-width: 90vw;
    max-height: 90vh;
    width: auto;
    height: auto;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
    position: absolute;
  }
}
