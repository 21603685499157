$default-color: "#1c9ad7";
$pro-tag: "#009dd9";
$link-pressed: "#0078b2";
$link-visited: "#85a7b8";
$demo-button-hover: "#5bc1f2";
$demo-button-hover-dark: "#64c8ee";
$svg-base: "#009dd9";
header {
  min-height: 48px;
  margin-bottom: 28px;
  background: white;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  border: 1px solid #ffffff;
  z-index: 2001;
  transition: 200ms ease-in-out all;
  &[data-copied="true"] {
    &:after {
      opacity: 0.9;
      transform: translate(-50%, 0px);
    }
  }
  &[data-copied="false"] {
    &:after {
      opacity: 0;
      transform: translate(-50%, -200px);
    }
  }
  &[data-copied-text="true"] {
    &:before {
      opacity: 0.9;
      transform: translate(-50%, 0px);
    }
  }
  &[data-copied-text="false"] {
    &:before {
      opacity: 0;
      transform: translate(-50%, -200px);
    }
  }
  &:before {
    transition: 450ms ease-in-out all;
    content: attr(data-code);
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translate(-50%, -200px);
    background: var(--default-color);
    color: white;
    font-size: 0.75rem;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    opacity: 0;
    z-index: 4;
  }
  &:after {
    transition: 450ms ease-in-out all;
    content: attr(data-link);
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translate(-50%, -200px);
    background: var(--default-color);
    color: white;
    font-size: 0.75rem;
    text-align: center;
    padding: 10px;
    border-radius: 8px;
    opacity: 0;
  }
  &[data-scrolled="true"] {
    border: 1px solid #ffffff;
    box-shadow: 0 2px 4px 0 rgba(33, 33, 33, 0.1),
      0 4px 15px 0 rgba(33, 33, 33, 0.15);
  }
  .container {
    width: 100%;
    max-width: 1920px;
    padding: 0px 5px;
    height: 48px;
    margin: auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .down-arrow {
      margin: 0px 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    @media (min-width: 768px) {
      padding: 0px 20px;
    }
    @media (min-width: 1240px) {
      padding: 0px 20px;
    }
    .left-combo {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      position: relative;
      height: 100%;
      .flex {
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .drop-menu {
        display: flex;
        flex-direction: row;
        align-items: center;
        user-select: none;
        padding: 0px 0px;
        height: 100%;
        width: 165px;

        .drop-menu-logo {
          min-height:24px;
          min-width:24px;
          max-height:24px;
          max-width:24px;
        }

        @media (min-width: 768px) {
          padding: 0px 10px 0 0;
          margin-right: -35px;
        }
        cursor: pointer;
        &:focus {
          outline: 0;
        }
        .title {
          color: #6a6a6a;
          font-family: "Roboto", sans-serif;
          font-size: 0.875rem;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 1.438rem;
          margin-left: 5px;
          margin-right: 0px;
        }
        .down-arrow {
          margin: 0px 5px 0px 13px;
          svg {
            transform: rotate(90deg);
          }
        }
        &:hover,
        &[data-menu-open="true"] {
          & + .dropdown {
            visibility: visible;
          }
        }
      }
      .dropdown {
        transition: all 150ms ease-in-out;
        position: absolute;
        visibility: hidden;
        min-width: 120px;
        color: black;
        background: linear-gradient(180deg, #ffffff 0%, #fafafa 100%);
        box-shadow: 0 2px 4px 0 rgba(33, 33, 33, 0.1),
          0 4px 15px 0 rgba(33, 33, 33, 0.15);
        top: 49px;
        left: -10px;
        width: 130px;
        display: flex;
        flex-direction: column;
        .title {
          color: #6a6a6a;
          font-family: "Roboto", sans-serif;
          font-size: 0.875rem;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 1.438rem;
          margin-left: 0px;
          margin-right: 0px;
        }
        a {
          cursor: pointer;
          padding: 10px 10px 10px 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          img {
            width: 28px;
            height: 28px;
            margin-right: 13px;
          }
          &:hover {
            background: #f5f5f5;
          }
        }
        &:hover {
          visibility: visible;
        }
      }
    }
    .right-combo {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      height: 100%;
      > *:first-child {
        margin: 0px 0px 0px 10px;
      }
      > *:nth-child(even) {
        margin: 0px 5px;
        @media (min-width: 400px) {
          margin: 0px 36px;
        }
      }

      .lang-control {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        height: 100%;
        cursor: pointer;
        user-select: none;
        display: flex;
        position: relative;
        @media (min-width: 768px) {
          display: flex;
        }
        .title {
          color: #6a6a6a;
          font-family: "Roboto", sans-serif;
          font-size: 0.875rem;
          font-weight: 500;
          letter-spacing: 0;
          line-height: 1.438rem;
          margin-left: 5px;
          margin-right: 0px;
        }
        .dropdown {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          user-select: none;
          width: 100px;
          border: 1px #f5f5f5 solid;
          right: 0;
          visibility: hidden;
          position: absolute;
          top: 48px;
          cursor: pointer;
          background: linear-gradient(180deg, #ffffff 0%, #fafafa 100%);
          box-shadow: 0 2px 4px 0 rgba(33, 33, 33, 0.1),
            0 4px 15px 0 rgba(33, 33, 33, 0.15);
          &:focus {
            outline: 0;
          }
          .title {
            color: #6a6a6a;
            font-family: "Roboto", sans-serif;
            font-size: 0.875rem;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 1.438rem;
            margin-left: 0px;
            margin-right: 0px;
          }
          a {
            cursor: pointer;
            padding: 10px 10px;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            &:hover {
              background: #f5f5f5;
            }
          }
        }
        &[data-menu-open="true"] {
          .dropdown {
            visibility: visible;
          }
        }
      }
    }
  }

  #toggle,
  #lang {
    display: block;
    cursor: pointer;
    @media (min-width: 768px) {
      display: block;
    }
  }

  .logo {
    width: 100%;
    height: auto;
  }

  a {
    text-decoration: none;
  }

  .search {
    position: relative;
    width: 100%;
    max-width: 612px;
    margin: 0px 2px;
    @media (min-width: 768px) {
      margin: 0px 20px;
    }
    svg#svg-search {
      position: absolute;
      left: 0px;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      transition: 150ms ease-in-out all;
      #Index {
        transition: 150ms ease-in-out all;
      }
    }
    svg#svg-cross {
      position: absolute;
      right: 15px;
      cursor: pointer;
      top: 50%;
      transform: translateY(-50%);
      transition: 150ms ease-in-out all;
      #Index {
        transition: 150ms ease-in-out all;
      }
      &.hide {
        display: none;
      }
    }
    input[type="text"],
    input[type="search"] {
      &::-webkit-search-cancel-button,
      &::-webkit-search-decoration {
        -webkit-appearance: none;
        appearance: none;
      }
      width: 100%;
      max-width: 612px;
      border: 0px;
      border-bottom: 1px solid #74727230;
      height: 40px;
      transition: 150ms ease-in-out all;
      outline: 0;
      padding: 5px 5px 5px 22px;
      position: relative;
      @media (min-width: 400px) {
        padding: 5px 20px 5px 40px;
      }
      font-size: 1rem;
      line-height: 1.188rem;
      &:focus {
        border-bottom: 1px solid var(--default-color);
        & + svg {
          #Index {
            fill: var(--default-color);
          }
        }
      }
    }
    .list {
      width: 100%;
      height: auto;
      max-height: 500px;
      position: absolute;
      transform: translateY(4px);
      box-shadow: 0 2px 4px 0 rgba(33, 33, 33, 0.1),
        0 4px 15px 0 rgba(33, 33, 33, 0.15);
      background: linear-gradient(180deg, #ffffff 0%, #fafafa 100%);
      transition: all 200ms ease-in-out;
      opacity: 1;
      @media only screen and (max-width: 768px) {
        position: fixed;
        left: 0;
        #results-container {
          padding: 12px !important;
        }
      }
      &[data-results] {
        &:before {
          content: attr(data-see-all);
          position: absolute;
          width: 90%;
          font-size: 0.8rem;
          color: #7f7f7f;
          padding: 4px 10px 4px 34px;
          z-index: 1;
          background-color: #ffffff;
        }
      }
      &[data-results-none] {
        &:before {
          content: attr(data-no-results);
          position: absolute;
          width: 90%;
          font-size: 0.8rem;
          color: #7f7f7f;
          padding: 4px 10px 4px 34px;
          z-index: 1;
          background-color: #ffffff;
        }
      }
      &[data-recent-searches] {
        &:before {
          content: attr(data-recent);
          position: absolute;
          width: 90%;
          font-size: 0.8rem;
          color: #7f7f7f;
          padding: 4px 10px 4px 34px;
          z-index: 1;
          background-color: #ffffff;
        }
      }
      &.empty {
        visibility: hidden;
        opacity: 0;
      }
      #results-container {
        list-style: none;
        max-width: 612px;
        width: 100%;
        border: 1px solid #ffffff;
        padding: 4px 16px 24px 32px;
        .recent {
          padding-top: 24px;
          a {
            display: block;
            padding: 4px;
            color: #858585;
            &.active,
            &:focus,
            &:hover {
              background: #f5f5f5;
            }
          }
        }
        .search-results-list {
          list-style: none;
          li {
            &:first-of-type {
              margin-top: 30px;
            }
            a {
              padding: 12px 16px;
              margin: 4px 0px;
              display: block;
              text-decoration: none;
              border: 1px solid transparent;
              &.active,
              &:focus,
              &:hover {
                background: #f5f5f5;
              }
              .search-result-doc-title {
                color: #212121;
                font-size: 1rem;
                font-weight: 300;
                letter-spacing: 0;
                line-height: 1rem;
              }
              .search-result-rel-url {
                color: #858585;
                font-size: 0.75rem;
                letter-spacing: 0;
                font-weight: 400;
                line-height: 0.75rem;
              }
              .search-result-preview {
                line-height: 1.125rem;
              }
              .search-result-preview span {
                color: #212121;
                font-size: 0.875rem;
                font-weight: 300;
                letter-spacing: 0;
              }
              .search-result-section {
                &,
                span,
                div {
                  color: #212121;
                  font-size: 0.875rem;
                  font-weight: 300;
                  letter-spacing: 0;
                  line-height: 0.875rem;
                }
              }
              .search-result-highlight {
                background: var(--default-color);
                padding: 2px 3px;
                color: #ffffff !important;
              }
            }
          }
        }
      }
    }
  }
  .side {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }
  #burger {
    cursor: pointer;
    outline: 0;
    user-select: none;
    // &:hover,
    // &:focus {
    //   #Desktop-HD-1 {
    //     fill: grey;
    //   }
    // }
    // #Desktop-HD-1 {
    //   transition: ease-in-out 150ms all;
    // }
    // &.open {
    //   #Desktop-HD-1 {
    //     fill: dodgerblue;
    //   }
    // }
  }
  .dropbox {
    position: absolute;
    min-height: 99px;
    // width: 168px;
    border: 1px solid #ffffff;
    background: linear-gradient(180deg, #ffffff 0%, #fafafa 100%);
    box-shadow: 0 2px 4px 0 rgba(33, 33, 33, 0.1),
      0 4px 15px 0 rgba(33, 33, 33, 0.15);
    padding: 10px 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 48px;
    right: 0;
    visibility: hidden;
    opacity: 0;
    transition: all 150ms ease-in-out;
    user-select: none;
    .drop-lang {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      cursor: pointer;
      svg {
        margin-right: 0px;
      }
      .title {
        color: #6a6a6a;
        font-family: "Roboto", sans-serif;
        font-size: 0.875rem;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 1.438rem;
        margin-left: 5px;
        margin-right: 0px;
      }
    }
    &[aria-hidden="false"] {
      visibility: visible;
      opacity: 1;
    }
    svg {
      margin-left: 0px;
      margin-right: 10px;
    }
    .row {
      margin-top: 10px;
      padding-top: 10px;
      border-top: 0.1px solid #d5d4d4;
      padding-left: 10px;
      &:first-of-type {
        margin-top: 0px;
        padding-top: 0px;
        border-top: 0px;
      }
      &:last-of-type {
        margin-top: 10px;
        padding-top: 10px;
        padding-left: 14px;
        border-top: 0.1px solid #d5d4d4;
      }
      &.top {
        padding-left: 0px;
        @media (min-width: 768px) {
          border-top: 0px;
          margin-top: 0px;
          padding-top: 10px;
        }
      }
      display: flex;
      flex-direction: row;
      align-items: center;
      height: 100%;
      .dl {
        color: #4b4b4b;
        font-family: "Roboto";
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 2rem;
      }
      .font {
        color: #4b4b4b;
        font-family: "Roboto";
        font-size: 0.875rem;
        letter-spacing: 0;
        line-height: 1.438rem;
        margin: 0 14px;
        &.large {
          font-size: 1.438rem;
        }
      }
    }
  }
  #font-resize {
    cursor: pointer;
    margin-right: 5px;
    user-select: none;
  }

  #toggle {
    width: 24px;
    height: 24px;
    &:before {
      content: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3E119CA6C0-E454-4EDC-AC50-8749B35575E2%3C/title%3E%3Cg id='чистовик' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Index' transform='translate(-1092.000000, -12.000000)'%3E%3Crect id='Rectangle' fill='%23FFFFFF' x='0' y='0' width='1200' height='7578'%3E%3C/rect%3E%3Cg id='panel/header' transform='translate(291.000000, 0.000000)'%3E%3Crect id='Rectangle-Copy' fill='%23FFFFFF' x='0' y='0' width='909' height='48'%3E%3C/rect%3E%3Cg id='icn/doc-copy-9' transform='translate(801.000000, 12.000000)' fill='%236A6A6A' fill-rule='nonzero'%3E%3Cg id='moon' transform='translate(7.000000, 6.000000)'%3E%3Cpath d='M11.292667,8.83825714 C11.1835527,8.7039 11.0003384,8.65422857 10.8391098,8.71204286 C10.3871813,8.87408571 9.89698125,8.95714286 9.38072411,8.95714286 C6.91099554,8.95714286 4.90215268,6.94748571 4.90215268,4.47857143 C4.90215268,2.9925 5.63582411,1.60902857 6.86458125,0.776828571 C7.00382411,0.682371429 7.07059554,0.512185714 7.03313839,0.348514286 C6.99568125,0.184842857 6.86132411,0.0610714286 6.69439554,0.0366428571 C6.50385268,0.00895714286 6.31412411,0 6.12358125,0 C2.75569554,0 0.0164383929,2.73925714 0.0164383929,6.10714286 C0.0164383929,9.47502857 2.75569554,12.2142857 6.12358125,12.2142857 C8.26108125,12.2142857 10.2055955,11.1280286 11.3236098,9.30891429 C11.4139955,9.16152857 11.400967,8.97342857 11.292667,8.83825714 Z' id='Path'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
  }
}
