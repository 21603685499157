@function svg-color($color) {
  @return '%23' + str-slice('#{$color}', 2, -1)
}

%send-result {
  &.success,
  &.fail {
    &:after {
      position: absolute;
      background: white;
      color: #212121;
      top: 0;
      right: 0;
      bottom: -.125rem;
      left: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      opacity: 0;
      transition: 250ms ease opacity;
    }
  }

  &.success {
    &:after {content: attr(data-success);}

    &.load {
      &:after {opacity: 1;}
    }
  }

  &.fail {
    &:after {content: attr(data-error);}

    &.load {
      &:after {opacity: 1;}
    }
  }

  html[data-theme="dark"] & {
    &.success,
    &.fail {
      &:after {
        background: #26292b;
        color: #898989;
      }
    }
  }
}
