$default-color: "#1c9ad7";
$pro-tag: "#009dd9";
$link-pressed: "#0078b2";
$link-visited: "#85a7b8";
$demo-button-hover: "#5bc1f2";
$demo-button-hover-dark: "#64c8ee";
$svg-base: "#009dd9";
@import "mixins";

pre {
  background-color: #f5f5f5;
  height: auto;
  /* margin-top: 0.3125rem; */
  color: #212121;
  font-family: "Inconsolata", serif !important;
  font-weight: var(--code-font-weight);
  font-size: 0.9rem;
  letter-spacing: 0;
  line-height: 1.35rem;
  padding: 0.5rem 3.625rem 1.125rem 1rem;
  outline: 0;
  border: 0px;
  border-left: 3px solid #bdbdbd;
  position: relative;
  overflow: auto;
}
.pre-wrap {
  position: relative;
  display: grid;
  margin: 1rem 0px;
}
table .pre-wrap {
  display: grid;
}
pre .button,
.pre-wrap .button {
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  right: 0;
  z-index: 1;
  cursor: pointer;
}

.pre-wrap .button:after {
  content: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-567.000000, -408.000000)'%3E%3Cg transform='translate(567.000000, 408.000000)'%3E%3Cg transform='translate(7.000000, 5.000000)' fill='%23212121' fill-rule='nonzero'%3E%3Cpath d='M6.28770302,12 L1.85614849,12 C0.832638492,12 0,11.158905 0,10.125 L0,3.7734375 C0,2.73953248 0.832638492,1.8984375 1.85614849,1.8984375 L6.28770302,1.8984375 C7.31121302,1.8984375 8.14385151,2.73953248 8.14385151,3.7734375 L8.14385151,10.125 C8.14385151,11.158905 7.31121302,12 6.28770302,12 Z M1.85614849,2.8359375 C1.34443879,2.8359375 0.928074246,3.25653075 0.928074246,3.7734375 L0.928074246,10.125 C0.928074246,10.6419068 1.34443879,11.0625 1.85614849,11.0625 L6.28770302,11.0625 C6.79941271,11.0625 7.21577726,10.6419068 7.21577726,10.125 L7.21577726,3.7734375 C7.21577726,3.25653075 6.79941271,2.8359375 6.28770302,2.8359375 L1.85614849,2.8359375 Z M10,8.953125 L10,1.875 C10,0.841094977 9.16736151,0 8.14385151,0 L2.99303944,0 C2.73673144,0 2.52900232,0.209838867 2.52900232,0.46875 C2.52900232,0.727661133 2.73673144,0.9375 2.99303944,0.9375 L8.14385151,0.9375 C8.65556121,0.9375 9.07192575,1.35809325 9.07192575,1.875 L9.07192575,8.953125 C9.07192575,9.21203613 9.27965487,9.421875 9.53596288,9.421875 C9.79227088,9.421875 10,9.21203613 10,8.953125 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  right: 0;
  cursor: pointer;
}

pre[data-copied="true"] .button:after,
.pre-wrap[data-copied="true"] .button:after {
  content: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-710.000000, -406.000000)'%3E%3Cg transform='translate(710.000000, 406.000000)'%3E%3Cg transform='translate(5.000000, 5.000000)' fill='#{svg-color($default-color)}' fill-rule='nonzero'%3E%3Cpath d='M10.3502393,4.85416885 C10.143142,4.61349747 9.79787467,4.6028975 9.57916246,4.83172356 L6.09703188,8.47130616 L4.43615406,6.59189729 C4.22579827,6.35403519 3.88089888,6.34882209 3.66489331,6.58068913 C3.44907169,6.81232447 3.44452559,7.19285169 3.65469743,7.43071379 L5.69103602,9.73493226 C5.79775118,9.8557603 5.93957381,9.91666667 6.08176434,9.91666667 C6.21667584,9.91666667 6.35158734,9.86175536 6.4572251,9.75175899 L10.3299001,5.70401974 C10.5484283,5.47557018 10.5576782,5.09504296 10.3502393,4.85416885 Z' %3E%3C/path%3E%3Cpath d='M7,0 C3.14015625,0 0,3.14015625 0,7 C0,10.8598437 3.14015625,14 7,14 C10.8598437,14 14,10.8598437 14,7 C14,3.14015625 10.8598438,0 7,0 Z M7,12.8648789 C3.76619141,12.8648789 1.13512109,10.2340273 1.13512109,7 C1.13512109,3.76619141 3.76616406,1.13512109 7,1.13512109 C10.234,1.13512109 12.8648789,3.76616406 12.8648789,7 C12.8648789,10.234 10.234,12.8648789 7,12.8648789 Z' %3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  width: 24px;
  height: 24px;
  right: 0;
  cursor: pointer;
}
code {
  font-family: "Inconsolata", serif !important;
  color: #212121;
  font-weight: var(--code-font-weight);
}

/* Gray DOCTYPE selectors like WebKit */
.xml .hljs-meta {
  color: #c0c0c0;
}

.hljs-comment,
.hljs-quote {
  color: #007400;
}

.hljs-tag,
.hljs-attribute,
.hljs-keyword,
.hljs-selector-tag,
.hljs-literal,
.hljs-name {
  color: #aa0d91;
}

.hljs-variable,
.hljs-template-variable {
  color: #3f6e74;
}

.hljs-code,
.hljs-string,
.hljs-meta-string {
  color: #c41a16;
}

.hljs-regexp,
.hljs-link {
  color: #0e0eff;
}

.hljs-title,
.hljs-symbol,
.hljs-bullet,
.hljs-number {
  color: #1c00cf;
}

.hljs-section,
.hljs-meta {
  color: #643820;
}

.hljs-class .hljs-title,
.hljs-type,
.hljs-built_in,
.hljs-builtin-name,
.hljs-params {
  color: #5c2699;
}

.hljs-attr {
  color: #836c28;
}

.hljs-subst {
  color: #000;
}

.hljs-formula {
  background-color: #eee;
  font-style: italic;
}

.hljs-addition {
  background-color: #baeeba;
}

.hljs-deletion {
  background-color: #ffc8bd;
}

.hljs-selector-id,
.hljs-selector-class {
  color: #9b703f;
}

.hljs-doctag,
.hljs-strong {
  font-weight: bold;
}

.hljs-emphasis {
  font-style: italic;
}

html[data-theme="dark"] {
  body{
    pre {
      background-color: #424345;
      border-left-color: #898989;
    }
    code {
      color: #898989;
    }
    .pre-wrap .button:after,
    .code .copy-icon:after {
      content: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-567.000000, -408.000000)'%3E%3Cg transform='translate(567.000000, 408.000000)'%3E%3Cg transform='translate(7.000000, 5.000000)' fill='%23898989' fill-rule='nonzero'%3E%3Cpath d='M6.28770302,12 L1.85614849,12 C0.832638492,12 0,11.158905 0,10.125 L0,3.7734375 C0,2.73953248 0.832638492,1.8984375 1.85614849,1.8984375 L6.28770302,1.8984375 C7.31121302,1.8984375 8.14385151,2.73953248 8.14385151,3.7734375 L8.14385151,10.125 C8.14385151,11.158905 7.31121302,12 6.28770302,12 Z M1.85614849,2.8359375 C1.34443879,2.8359375 0.928074246,3.25653075 0.928074246,3.7734375 L0.928074246,10.125 C0.928074246,10.6419068 1.34443879,11.0625 1.85614849,11.0625 L6.28770302,11.0625 C6.79941271,11.0625 7.21577726,10.6419068 7.21577726,10.125 L7.21577726,3.7734375 C7.21577726,3.25653075 6.79941271,2.8359375 6.28770302,2.8359375 L1.85614849,2.8359375 Z M10,8.953125 L10,1.875 C10,0.841094977 9.16736151,0 8.14385151,0 L2.99303944,0 C2.73673144,0 2.52900232,0.209838867 2.52900232,0.46875 C2.52900232,0.727661133 2.73673144,0.9375 2.99303944,0.9375 L8.14385151,0.9375 C8.65556121,0.9375 9.07192575,1.35809325 9.07192575,1.875 L9.07192575,8.953125 C9.07192575,9.21203613 9.27965487,9.421875 9.53596288,9.421875 C9.79227088,9.421875 10,9.21203613 10,8.953125 Z'%3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }
    pre[data-copied="true"] .button:after,
    .pre-wrap[data-copied="true"] .button:after {
      content: url("data:image/svg+xml,%3Csvg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg transform='translate(-710.000000, -406.000000)'%3E%3Cg transform='translate(710.000000, 406.000000)'%3E%3Cg transform='translate(5.000000, 5.000000)' fill='#{svg-color($default-color)}' fill-rule='nonzero'%3E%3Cpath d='M10.3502393,4.85416885 C10.143142,4.61349747 9.79787467,4.6028975 9.57916246,4.83172356 L6.09703188,8.47130616 L4.43615406,6.59189729 C4.22579827,6.35403519 3.88089888,6.34882209 3.66489331,6.58068913 C3.44907169,6.81232447 3.44452559,7.19285169 3.65469743,7.43071379 L5.69103602,9.73493226 C5.79775118,9.8557603 5.93957381,9.91666667 6.08176434,9.91666667 C6.21667584,9.91666667 6.35158734,9.86175536 6.4572251,9.75175899 L10.3299001,5.70401974 C10.5484283,5.47557018 10.5576782,5.09504296 10.3502393,4.85416885 Z' %3E%3C/path%3E%3Cpath d='M7,0 C3.14015625,0 0,3.14015625 0,7 C0,10.8598437 3.14015625,14 7,14 C10.8598437,14 14,10.8598437 14,7 C14,3.14015625 10.8598438,0 7,0 Z M7,12.8648789 C3.76619141,12.8648789 1.13512109,10.2340273 1.13512109,7 C1.13512109,3.76619141 3.76616406,1.13512109 7,1.13512109 C10.234,1.13512109 12.8648789,3.76616406 12.8648789,7 C12.8648789,10.234 10.234,12.8648789 7,12.8648789 Z' %3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
    }

      /* Gray DOCTYPE selectors like WebKit */
    .xml .hljs-meta {
      color: #c0c0c0;
    }

    .hljs-comment,
    .hljs-quote {
      color: #359935;
    }

    .hljs-tag,
    .hljs-attribute,
    .hljs-keyword,
    .hljs-selector-tag,
    .hljs-literal,
    .hljs-name {
      color: #d26dc2;
    }

    .hljs-variable,
    .hljs-template-variable {
      color: #3f6e74;
    }

    .hljs-code,
    .hljs-string,
    .hljs-meta-string {
      color: #ff6363;
    }

    .hljs-regexp,
    .hljs-link {
      color: #0e0eff;
    }

    .hljs-title,
    .hljs-symbol,
    .hljs-bullet,
    .hljs-number {
      color: #2f87d0;
    }

    .hljs-section,
    .hljs-meta {
      color: #ff8340;
    }

    .hljs-class .hljs-title,
    .hljs-type,
    .hljs-built_in,
    .hljs-builtin-name,
    .hljs-params {
      color: #b473ff;
    }

    .hljs-attr {
      color: #c8a540;
    }

    .hljs-subst {
      color: #000;
    }

    .hljs-formula {
      background-color: #eee;
      font-style: italic;
    }

    .hljs-addition {
      background-color: #baeeba;
    }

    .hljs-deletion {
      background-color: #ffc8bd;
    }

    .hljs-selector-id,
    .hljs-selector-class {
      color: #c8a540;
    }

    .hljs-doctag,
    .hljs-strong {
      font-weight: bold;
    }

    .hljs-emphasis {
      font-style: italic;
    }
  }
}
