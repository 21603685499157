$default-color: "#1c9ad7";
$pro-tag: "#009dd9";
$link-pressed: "#0078b2";
$link-visited: "#85a7b8";
$demo-button-hover: "#5bc1f2";
$demo-button-hover-dark: "#64c8ee";
$svg-base: "#009dd9";
.cookie {
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  color: #212121;
  font-size: 0.8125rem;
  font-weight: var(--font-weight);
  letter-spacing: 0;
  line-height: 0.9375rem;
  background: #D8D8D8;
  padding-right: 20px;
  align-items: center;
  z-index: 2000000;
  a {
    color: var(--default-color) !important;
    text-decoration: none;
  }
  .close {
    margin-left: auto;
    margin-right: 6px;
    position: relative;
    cursor: pointer;
    height: 24px;
  }
}
html[data-theme] body .cookie a {
  color: var(--default-color) !important;
  text-decoration: none;
}
