$default-color: "#1c9ad7";
$pro-tag: "#009dd9";
$link-pressed: "#0078b2";
$link-visited: "#85a7b8";
$demo-button-hover: "#5bc1f2";
$demo-button-hover-dark: "#64c8ee";
$svg-base: "#009dd9";
.breadcrumb {
  padding: 5px 0px 1rem 30px;
  width: 100%;
  &.adjust {
    padding: 5px 0px 1rem 40px;
  }
  #breadcrumbs {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    a,
    span {
      font-weight: var(--font-weight);
      text-decoration: none;
      color: black;
      margin-right: 0.3125rem;
    }
    svg {
      margin-right: 0.3125rem;
    }
  }
}
