$default-color: "#1c9ad7";
$pro-tag: "#009dd9";
$link-pressed: "#0078b2";
$link-visited: "#85a7b8";
$demo-button-hover: "#5bc1f2";
$demo-button-hover-dark: "#64c8ee";
$svg-base: "#009dd9";
.grid {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: auto;
  justify-content: center;
  &.home {
    .grid-item {
      border: 1px solid white !important;
      &:hover,
      &:focus {
        border: 1px solid var(--default-color) !important;
      }
    }
  }
  .grid-item {
    display: block;
    width: 100%;
    background: white;
    border: 1px solid #e6e6e6;
    margin: 6px 0px;
    padding: 15px 18px;
    text-decoration: none;
    transition: 150ms ease all;

    p {
      margin: 0px;
    }
    &.empty {
      min-height: 0px !important;
      height: 0px !important;
      border: 0px !important;
      padding: 0px !important;
    }
    & {
      &:hover,
      &:focus {
        background: #f5f5f5;
        text-decoration: none !important;
      }
    }

    &.main {
      max-width: 410px;
      background: white;
      border: 1px solid #e6e6e6;
      margin: 12px;
      padding: 25px 20px;
      text-decoration: none;
      transition: 150ms ease all;
      width: 370px;
      min-height: 230px;
      text-align: center;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      &:hover,
      &:focus {
        background: white !important;
        border: 1px solid var(--default-color) !important;
      }
      span {
        width: 64px;
        height: 64px;
        display: block;
        color: var(--default-color);
        border-radius: 12px;
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        img {
          height: inherit;
          max-height: 64px;
        }
      }
      .title {
        font-size: 1.875rem;
        font-weight: var(--font-weight);
        letter-spacing: 0;
        line-height: 2.25rem;
      }
    }

    .title {
      color: #212121;
      font-size: 1.0625rem;
      font-weight: var(--font-weight);
      letter-spacing: 0;
      line-height: 1.875rem;
      &.center {
        margin: auto !important;
      }
    }
    .text {
      color: #212121;
      font-size: 0.8rem;
      font-weight: var(--font-weight);
      letter-spacing: 0;
      line-height: 1.625rem;
      margin-top: 10px;
    }
  }
}
