$default-color: "#1c9ad7";
$pro-tag: "#009dd9";
$link-pressed: "#0078b2";
$link-visited: "#85a7b8";
$demo-button-hover: "#5bc1f2";
$demo-button-hover-dark: "#64c8ee";
$svg-base: "#009dd9";
footer {
  background: #f7f7f7;
  position: relative;

  a {
    // display: block;
    text-decoration: none;
    color: black;
  }

  .container {
    width: 100%;
    max-width: 1200px;
    margin: auto;
    padding: 45px 30px 35px;
    // min-height: 166px;
    // padding-top: 45px;
    // padding-bottom: 35px;
    // padding-top: 40px;
    // padding-bottom: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (min-width: 768px) {
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }

    .box {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 20px;

      @media (min-width: 768px) {
        align-items: flex-start;
        margin-bottom: 0px;
      }

      &.logo {
        order: 1;

        svg {
          margin: 8px auto;
        }
      }

      &.prod {
        order: 2;
      }

      &.sup {
        order: 3;
      }

      &.res {
        order: 4;
      }

      &.fol {
        order: 5;
      }

      @media (min-width: 768px) {
        &.logo {
          order: 1;
        }

        &.prod {
          order: 2;
        }

        &.sup {
          order: 3;
        }

        &.res {
          order: 4;
        }

        &.fol {
          order: 5;
        }
      }
    }

    .heading {
      text-transform: uppercase;
      color: #9e9e9e;
      font-family: "Roboto", sans-serif;
      font-size: 0.688rem;
      letter-spacing: 0;
      line-height: 1.875rem;
    }

    .text {
      color: #000000;
      font-family: "Roboto", sans-serif;
      font-size: 0.813rem;
      font-weight: var(--font-weight);
      letter-spacing: 0;
      line-height: 1.875rem;
      text-decoration: none;
      display: block;
    }

    .social-media {
      width: 100%;
      display: flex;
      flex-direction: row;

    }


  }
}