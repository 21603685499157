$default-color: "#1c9ad7";
$pro-tag: "#009dd9";
$link-pressed: "#0078b2";
$link-visited: "#85a7b8";
$demo-button-hover: "#5bc1f2";
$demo-button-hover-dark: "#64c8ee";
$svg-base: "#009dd9";
.table-of-contents {
  font-weight: var(--font-weight);
  z-index: 2000;
  position: fixed;
  font-family: "Roboto", sans-serif;
  width: 100%;
  height: 100%;
  user-select: none;
  visibility: none;
  left: 0px;
  top: 0px;
  padding-top: 80px;
  padding-right: 20px;
  background: white;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  &.hide {
    min-width: 0px;
    width: 0px;
    background: none !important;
  }
  &.show {
    visibility: visible;
    display: flex;
  }
  @media (min-width: 600px) {
    z-index: 2000;
    padding-top: 0px;
    padding-right: 0px;
    padding-bottom: 20px;
    background: transparent;
    position: relative;
    visibility: hidden;
    width: 0px;
    min-width: 0px;
    top: 0px;
    height: auto;
  }
  @media (min-width: 768px) {
    visibility: visible;
  }
  #hide-toc {
    display: none;
    &:checked {
      @media (min-width: 768px) {
      }
      & + label {
        svg {
          transform: translate(0%, -50%) rotate(180deg);
          position: absolute;
          top: 0;
          left: 0;
        }
        & + nav {
          display: none;
        }
      }
    }
  }
  .hide-label {
    height: 24px;
  }
  #hide-label {
    outline: 0;
    position: sticky;
    z-index: 2;
    top: 96px;
    right: 0;

    svg {
      position: absolute;
      top: 0;
      right: 0;
      //left: 255px;
      transform: translate(50%, -50%) rotate(0deg);
      border-radius: 50%;
      cursor: pointer;
      z-index: 5;
      opacity: 1;
      visibility: visible;
      transition: 250ms ease-in-out opacity;
      user-select: none;
      &.hide {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  nav {
    width: 100%;
    border-right: 1px #dedede solid;
    position: relative;
    user-select: none;
    height: 100%;
    background: white;
    word-break: break-word;
    @media (min-width: 600px) {
      max-width: 300px;
      position: relative;
      user-select: none;
      display: block;
      top: 0px;
      height: 100%;
      margin-bottom: 40px;
      background: white;
    }
    ul {
      max-height: 100%;
      &#root-ul {
        position: relative;
        @media (min-width: 600px) {
          position: sticky;
          top: 80px;
          max-height: calc(100vh - 90px);
        }
      }
      list-style-type: none;
      li {
        a {
          min-height: 32px;
          padding-left: 16px;
        }
        ul {
          display: none;
          padding-left: 18px;
          li {
            a {
              min-height: 32px;
              padding-left: 16px;
              padding-right: 40px;
            }
          }
        }
      }
    }
    li {
      a {
        text-decoration: none;
        color: black;
        display: block;
        padding: 6px 22px 6px 6px;
        border-left: 4px white solid;
        &:hover,
        &:focus {
          border-left: 4px #f5f5f5 solid;
          background: #f5f5f5;
          font-family: "Roboto", sans-serif;
        }
        &[aria-current="page"] {
          border-left: 4px var(--default-color) solid;
          font-family: "Roboto", sans-serif;
        }
      }
    }
    label {
      display: block;
      display: flex;
      align-items: center;
      cursor: pointer;
      position: relative;
      &:hover,
      &:focus {
        background: #f5f5f5;
        & > a {
          border-left: 4px #f5f5f5 solid;
          background: #f5f5f5;
          &[aria-current="page"] {
            border-left: 4px var(--default-color) solid;
          }
        }
      }
      &[aria-current="page"] {
        & > a {
          border-left: 4px var(--default-color) solid;
        }
      }
      a {
        display: inline-block;
        width: 100%;
        padding: 0px;
        border-left: 0px;
        padding: 6px 22px 6px 0px;
        border-left: 4px white solid;
      }
      span {
        border-radius: 50%;
        height: 16px;
        width: 16px;
        transition: 0.1s all;
        display: flex;
        justify-content: center;
        align-items: center;
        outline: 0;
        position: absolute;
        right: 10px;
        top: 10px;
      }
    }
    input[type="checkbox"] {
      display: none;
      position: relative;
      &:checked {
        & + label {
          & + ul {
            display: block;
          }
          span {
            transform: rotate(90deg);
          }
        }
      }
    }
  }
}
