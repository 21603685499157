$default-color: "#1c9ad7";
$pro-tag: "#009dd9";
$link-pressed: "#0078b2";
$link-visited: "#85a7b8";
$demo-button-hover: "#5bc1f2";
$demo-button-hover-dark: "#64c8ee";
$svg-base: "#009dd9";
.content.-md .related,
.related {
  line-height: 1.5rem;
  &::before {
    content: attr(data-text);
    text-transform: uppercase;
    font-size: 0.688rem;
    color: #9e9e9e;
    font-weight: var(--font-weight);
  }
  a {
    display: block;
    color: black;
    text-decoration: none;
    font-size: 0.875rem;
    line-height: 1.625rem;
    font-weight: var(--font-weight);
    margin: 2px 0px;
    &:first-of-type {
      margin-top: 10px;
    }
    &:last-of-type {
      margin-bottom: 20px;
    }
    &:hover,
    &:focus {
      font-weight: 500;
    }
  }
}
